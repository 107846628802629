import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {isNullOrUndefined} from 'util';
import { StorageService } from '../../../../services/storage.service';
import { AuthService } from 'src/app/services/auth.service';
import { BaseLayoutComponent } from '../base-layout/base-layout.component';

@Component({
    selector: 'app-admin-layout',
    templateUrl: './admin-layout.component.html'
})
export class AdminLayoutComponent extends BaseLayoutComponent implements OnInit {

    currentLang: string = 'en';
    availableLangs = [{
        name: 'English',
        code: 'en'
    },
        {
            name: 'Deutsch',
            code: 'de'
        },
        // {
        //     name: 'Polskie',
        //     code: 'pl'
        // },
        {
            name: 'Nederlands',
            code: 'nl'
        },
        {
            name: 'Français',
            code: 'fr'
        }];

    constructor(protected router: Router,
                public route: ActivatedRoute,
                public translate: TranslateService,
                protected storage: StorageService,
                protected authService: AuthService) {
        super(router, route, translate, storage, authService);
    }

    ngOnInit() {
        super.ngOnInit();

        if (this.router.url.includes('de/sessions')) {
            localStorage.setItem('lang', 'de');
            this.translate.use('de');
        } else if (this.router.url.includes('en/sessions')) {
            localStorage.setItem('lang', 'en');
            this.translate.use('en');
        } else {
            if (isNullOrUndefined(localStorage.getItem('lang'))) {
                localStorage.setItem('lang', 'en');
                this.translate.use('en');
            }
        }

        if (localStorage.getItem('lang')) {
            this.currentLang = localStorage.getItem('lang');
        } else if (this.translate.getBrowserLang()) {
            this.currentLang = this.translate.getBrowserLang();
        } else {
            this.currentLang = 'en';
        }
        this.translate.use(this.currentLang);
    }

    setLang() {
        localStorage.setItem('lang', this.currentLang);
        this.translate.use(this.currentLang);
    }

    signout() {
        this.authService.logout()
            .subscribe(
                (result) => {
                    window.location.reload();
                },
                (err) => {
                    this.router.navigate(['/']);
                });
    }
}
