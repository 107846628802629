import {Component, EventEmitter, HostListener, NgZone, OnInit, Output, ViewChild} from '@angular/core';
import {MatButton, MatSnackBar} from '@angular/material';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AuthService} from '../../../services/auth.service';
import {StorageService} from '../../../services/storage.service';
import {DataService} from '../../../services/data.service';
import {TranslateService} from '@ngx-translate/core';
import {isNullOrUndefined} from 'util';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'app-signin-common',
    templateUrl: './signin-common.component.html'
})

export class SigninCommonComponent implements OnInit {
    env: any = environment;

    @Output() close: EventEmitter<boolean> = new EventEmitter();
    @Output() signinSuccess: EventEmitter<any> = new EventEmitter();

    notificationText: string;

    signinData = {
        email: '',
        password: ''
    };
    activationUserId: string;
    rememberMe: boolean = true;
    emailFocused: boolean = false;

    constructor(public translate: TranslateService,
                public route: ActivatedRoute,
                public snackBar: MatSnackBar,
                protected dataService: DataService,
                protected authService: AuthService,
                protected router: Router,
                protected zone: NgZone,
                protected storage: StorageService) {
    }

    @HostListener('window:keyup', ['$event'])
    keyEvent(event: KeyboardEvent) {
        if (event.key === 'enter') {
           this.signin();
        }
    }

    ngOnInit() {
        this.notificationText = this.dataService.strData;
        this.dataService.strData = '';
    }

    signin() {
        this.authService.login(this.signinData.email, this.signinData.password)
            .subscribe(
                (result) => {
                    if (this.rememberMe) {
                        this.storage.setStorage(localStorage);
                        localStorage.setItem('rememberMe', 'true');
                    } else {
                        this.storage.setStorage(sessionStorage);
                        localStorage.setItem('rememberMe', 'false');
                    }
                    localStorage.setItem('lang', result.lang);
                    this.storage.setItem('email', result.email);
                    this.storage.setItem('name', result.name);
                    this.storage.setItem('token', result.token);
                    this.storage.setItem('role', result.role);
                    this.storage.setItem('userId', result.userId);
                    this.storage.setItem('avatarDirName', result.avatarDirName);
                    this.storage.setItem('avatarFileName', result.avatarFileName);
                    this.storage.setItem('lang', result.lang);
                    this.storage.setItem('isLoggedin', 'true');
                    this.storage.setItem('kycPassed', result.kycPassed);

                    this.signinSuccess.emit(result);
                },
                (err) => {
                    if (!isNullOrUndefined(err.data) && !isNullOrUndefined(err.data.id)) {
                        this.activationUserId = err.data.id;
                    }

                    this.snackBar.open(err, this.translate.instant('CLOSE'), {duration: 3000});
                });
    }

    navigateToSignup() {
        this.signinSuccess.emit();

        this.router.navigate(['/sessions/signup']);
    }

    navigateToForgotPage() {
        this.signinSuccess.emit();

        this.router.navigate(['/sessions/forgot-password']);
    }

    resendActivationEmail() {
        this.authService.resendActivationEmail(this.activationUserId)
            .subscribe(
                (result) => {
                    this.snackBar
                        .open(this.translate.instant('CONFIGURATION.ACTIVATION_EMAIL_SENT'),
                            this.translate.instant('CLOSE'), {duration: 3000});
                },
                (err) => {
                    this.snackBar.open(err, this.translate.instant('CLOSE'), {duration: 3000});
                });
    }

    checking() {
        this.rememberMe = !this.rememberMe;
    }
}

