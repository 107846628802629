import { Injectable } from '@angular/core';
import {NetworkHelper} from '../helpers/network.helper';
import {Observable} from 'rxjs/Observable';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {environment} from '../../environments/environment';
import {StorageService} from './storage.service';

@Injectable()
export class OrderService {
    private hostUrl: string;

    constructor(private networkHelper: NetworkHelper,
                private storage: StorageService) {
        this.hostUrl = environment.host;
    }

    create(gameId, cellNumber: number): Observable<any> {
        return this.networkHelper.post(`${this.hostUrl}/order/create`,
        {
                gameId: gameId,
                cellNumber: cellNumber
            });
    }

    getOrder(id: string): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/order/${id}`, {});
    }

    allActives(id: string): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/order/get-actives/${id}`);
    }

}
