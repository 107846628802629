import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {StorageService} from '../../../../services/storage.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from '../../../../services/auth.service';
import {GameService} from '../../../../services/game.service';
import {OrderService} from '../../../../services/order.service';
import {isNullOrUndefined} from 'util';
import {BaseLayoutComponent} from '../base-layout/base-layout.component';
import {UserService} from '../../../../services/user.service';
import {MatSnackBar} from '@angular/material';
import {DataService} from '../../../../services/data.service';




@Component({
    selector: 'app-main-layout',
    templateUrl: './main-layout.component.html',

})
export class MainLayoutComponent extends BaseLayoutComponent implements OnInit, OnDestroy {
    isAuth: boolean = false;
    username: string = '';
    currentLang: string = 'en';
    wallet: any = {};
    fixHeader = false;
    subscribeEmail = '';


    constructor(protected router: Router,
                public route: ActivatedRoute,
                public translate: TranslateService,
                protected storage: StorageService,
                protected authService: AuthService,
                private  gameService: GameService,
                private orderService: OrderService,
                private storageService: StorageService,
                private userService: UserService,
                private snackBar: MatSnackBar,
                public data: DataService,
               ) {
        super(router, route, translate, storage, authService);


        if (this.storageService.getItem('userId')) {
            this.isAuth = true;
            this.username = this.storageService.getItem('name');
            this.getWallet();
        }
    }


    subscribe() {
        this.userService.newsLetter(this.subscribeEmail).subscribe(
            (result) => {
                this.subscribeEmail = '';
                this.snackBar.open('Thank You For Subscribing!',
                    this.translate.instant('CLOSE'),
                    {duration: 3000});
            },
            (err) => {
                this.snackBar.open(err,
                    this.translate.instant('CLOSE'),
                    {duration: 3000});
            }
        );
    }

    ngOnInit() {
        window.addEventListener('scroll', this.scroll.bind(this), true);
        this.scroll();
        super.ngOnInit();

        if (this.router.url.includes('de/sessions')) {
            localStorage.setItem('lang', 'de');
            this.translate.use('de');
        } else if (this.router.url.includes('en/sessions')) {
            localStorage.setItem('lang', 'en');
            this.translate.use('en');
        } else {
            if (isNullOrUndefined(localStorage.getItem('lang'))) {
                localStorage.setItem('lang', 'en');
                this.translate.use('en');
            }
        }

        if (localStorage.getItem('lang')) {
            this.currentLang = localStorage.getItem('lang');
        } else if (this.translate.getBrowserLang()) {
            this.currentLang = this.translate.getBrowserLang();
        } else {
            this.currentLang = 'en';
        }
        this.translate.use(this.currentLang);

        this.userService.events$.forEach(event => this.getWallet());

    }

    scroll() {
        const pos = (document.documentElement.scrollTop || document.body.scrollTop);
        this.fixHeader = pos > 50;
    }

    getWallet() {
        this.userService.getWallet().subscribe(
            (result) => {
                this.wallet = result.result;
            },
            (err) => {}
        );
    }

    logOut() {
        this.authService.logout().subscribe(
            (result) => {
            },
            (err) => {
            }
        );
    }

    ngOnDestroy() {
        window.removeEventListener('scroll', this.scroll, true);
    }


}
