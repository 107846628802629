<header>
    <div class="header-menu">
        <div class="logo">
            <a [routerLink]="['/dashboard']"><img src="/assets/images/cryptatory-logo.png" alt="" class="criptarory-logo"></a>
        </div>
        <div class="right-menu">
            <button mat-button [matMenuTriggerFor]="menu" class="person-icon"><i class="material-icons">person</i></button>
            <mat-menu #menu="matMenu">
                <div>
                    <a class="signout-btn" [routerLink]="['/admin/profile']">
                        <span>{{ 'PROFILE' | translate }}</span>
                    </a>
                </div>
                <div>
                    <button class="signout-btn" (click)="signout()">
                        <span>{{ 'ACTIONS.SIGN_OUT' | translate }}</span>
                    </button>
                </div>
            </mat-menu>
        </div>
    </div>
</header>
<div class="flex fill-1-0">
    <router-outlet></router-outlet>
</div>
