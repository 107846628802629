import {Component, EventEmitter, NgZone, OnInit, Output, ViewChild} from '@angular/core';
import {MatButton, MatSnackBar} from '@angular/material';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../../services/auth.service';
import {StorageService} from '../../../services/storage.service';
import {DataService} from '../../../services/data.service';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'app-signin-dialog',
    templateUrl: './signin-dialog.component.html'
})

export class SigninDialogComponent {
    env: any = environment;

    @ViewChild('submitButton', {static: false}) submitButton: MatButton;

    @Output() close: EventEmitter<boolean> = new EventEmitter();

    constructor(public translate: TranslateService,
                public route: ActivatedRoute,
                public snackBar: MatSnackBar,
                protected authService: AuthService,
                protected router: Router,
                protected storage: StorageService) {
    }

    signinSuccess(event) {
        this.close.emit(event);
    }
}
