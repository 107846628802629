import {Routes} from '@angular/router';
import { GuestService } from './services/guest.service';
import { AuthService } from './services/auth.service';
import { GuestLayoutComponent } from './components/common/layouts/guest-layout/guest-layout.component';
import { AdminLayoutComponent } from './components/common/layouts/admin-layout/admin-layout.component';
import { MainLayoutComponent } from './components/common/layouts/main-layout/main-layout.component';
import {ContactComponent} from './views/main/contact/contact.component';

export const rootRouterConfig: Routes = [
    {
        path: '',
        component: MainLayoutComponent,
        loadChildren: './views/main/main.module#MainModule',
        data: {title: 'Crypto Lotto'},
    },
    {
        path: 'admin',
        component: AdminLayoutComponent,
        canActivate: [AuthService],
        loadChildren: './views/admin/admin.module#AdminModule',
        data: {title: 'Admin Dashboard'}
    },
    {
        path: 'sessions',
        component: MainLayoutComponent,
        canActivate: [GuestService],
        loadChildren: './views/sessions/sessions.module#SessionsModule',
        data: {title: 'Sessions'}
    },
    {
        path: '**',
        redirectTo: ''
    }
];

