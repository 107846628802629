import {Injectable} from '@angular/core';
import { BehaviorSubject } from 'rxjs';



@Injectable({
    providedIn: 'root'
})
export class DataService {
    strData: string;
    objData: any;

   /* public showSource = new BehaviorSubject(false);
    currentShow = this.showSource.asObservable();

    /!*public show 2: boolean = false;*!/


     changeShow (show: boolean) {
         this.showSource.next(show);
     }*/
}


