import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatTabsModule} from '@angular/material/tabs';

import {rootRouterConfig} from './app.routes';
import {AppCommonModule} from './components/common/app-common.module';
import {AppComponent} from './app.component';

import {AuthService} from './services/auth.service';
import { GuestService } from './services/guest.service';
import {UserService} from './services/user.service';
import {StorageService} from './services/storage.service';
import {LoggerService} from './services/logger.service';
import {DataService} from './services/data.service';
import {ClipboardService} from './services/clipboard.service';
import {GameService} from './services/game.service';
import {OrderService} from './services/order.service';

import {environment} from '../environments/environment';
import {NetworkHelper} from './helpers/network.helper';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {AppUtl} from './helpers/app-utl';
import {QuillModule} from 'ngx-quill';
import { TokenInterceptor } from './services/token.interceptor';
import { RoutePartsService } from './services/route-parts.service';
import {MatPaginatorIntl} from '@angular/material';
import {PaginatorIntlService} from './components/mat-paginator/paginator-intl';
import {CommonPipesModule} from './pipes/common-pipes.module';
import {TimerService} from './services/countdown.service';


export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json?version='
        + environment.version);
}

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppCommonModule,
        HttpClientModule,
        MatTabsModule,
        CommonPipesModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        RouterModule.forRoot(rootRouterConfig, { useHash: false }),
        QuillModule,
    ],
    declarations: [
        AppComponent,

    ],
    providers: [
        AppUtl,
        LoggerService,
        StorageService,
        AuthService,
        GuestService,
        UserService,
        DataService,
        NetworkHelper,
        ClipboardService,
        RoutePartsService,
        GameService,
        OrderService,
        TimerService,
        {
            provide: MatPaginatorIntl,
            useFactory: (translate) => {
                const service = new PaginatorIntlService();
                service.injectTranslateService(translate);
                return service;
            },
            deps: [TranslateService]
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
