import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {NetworkHelper} from '../helpers/network.helper';
import {Observable} from 'rxjs/Observable';
import {map} from 'rxjs/operators';
import {throwError} from 'rxjs';
import {environment} from '../../environments/environment';
import {StorageService} from './storage.service';
import {isNullOrUndefined} from 'util';

@Injectable()
export class AuthService implements CanActivate {
    private hostUrl: string;
    private isAuthenticated = true; // Set this value dynamically
    private redirectUrl: string = '/';

    constructor(private router: Router, private networkHelper: NetworkHelper, private storage: StorageService) {
        this.hostUrl = environment.host;
        this.isAuthenticated = this.storage.getItem('isLoggedin') === 'true';
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.isAuthenticated) {
            return true;
        }

        if (state.url !== '/' && !state.url.includes('sessions/')) {
            this.redirectUrl = state.url;
        }
        const lang: string = localStorage.getItem('lang');
        if (isNullOrUndefined(lang)) {
            this.router.navigate(['/sessions/signin']);
        } else {
            this.router.navigate([`/${lang}/sessions/signin`]);
        }
        return false;
    }

    registerSteps(data, stepId): Observable<any> {
        let token = '';
        if (this.storage.getItem('token')) {
            token = this.storage.getItem('token');
        }

        return this.networkHelper.post(`${this.hostUrl}/user/steps/${stepId}`, data, token);
    }

    register(data): Observable<any> {
        return this.networkHelper.post(`${this.hostUrl}/user/register`, data, '');
    }

    login(email, password): Observable<any> {
        return this.networkHelper.post<any>(`${this.hostUrl}/auth/login`, {}, `${email}:${password}`)
            .pipe(map((result) => {
                    this.isAuthenticated = true;

                    if (result.result.role === 'admin') {
                        this.router.navigate(['/admin']);
                    }

                    return result.result;
                },
                (error) => {
                    return throwError(error);
                }));
    }


    activate(token): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/auth/activate/${token}`, {}, '');
    }

    logout(): Observable<any> {
        return this.networkHelper.post(`${this.hostUrl}/auth/logout`, {})
            .pipe(map((result) => {
                this.clearStorage();
                window.location.reload();
            }));
    }

    public clearStorage() {
        this.storage.removeItem('userId');
        this.storage.removeItem('email');
        this.storage.removeItem('name');
        this.storage.removeItem('role');
        this.storage.removeItem('avatarDirName');
        this.storage.removeItem('avatarFileName');
        this.storage.removeItem('token');
        this.storage.removeItem('lang');
        this.storage.removeItem('isLoggedin');
        this.storage.removeItem('isSyncing');
        this.storage.removeItem('kycPassed');
        this.storage.removeItem('registerStep');
    }

    forgotStep1(email, password): Observable<any> {
        return this.networkHelper.post(`${this.hostUrl}/auth/forgot-step-1`, {email: email, password: password}, '');
    }

    forgotStep2(token, password): Observable<any> {
        return this.networkHelper.post(`${this.hostUrl}/auth/forgot-step-2`, {token: token, password: password}, '');
    }

    resendActivationEmail(userId): Observable<any> {
        return this.networkHelper.post(`${this.hostUrl}/user/resend-activation-email/${userId}`, {}, '');
    }

    changePassword(oldPassword, newPassword): Observable<any> {
        return this.networkHelper.post(`${this.hostUrl}/auth/change-password`, {oldPassword: oldPassword, newPassword: newPassword});
    }
}
