<div class="login-registration-area">
    <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="login" role="tabpanel" aria-labelledby="login-tab">
            <div class="demo-info">
                <div>Login: demo@demo.com</div>
                <div>Pass: 123456789</div>
            </div>
            <form class="login-form cmn-frm">
                <div class="frm-group">
                    <label>Username or Email Address</label>
                    <input type="email" [(ngModel)]="signinData.email" name="login_email" id="login_email" placeholder="Username or Email Address">
                    <i class="fa fa-user"></i>
                </div>
                <div class="frm-group">
                    <label>Password</label>
                    <input type="password" [(ngModel)]="signinData.password" name="login_pass" id="login_pass" placeholder="Enter Password">
                    <i class="fa fa-lock"></i>
                </div>
                <div class="frm-group">
                    <input type="submit" (click)="signin()" name="registration_submit2" id="registration_submit2" value="login">
                </div>
            </form>
        </div>
    </div>
</div>
