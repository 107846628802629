import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {FlexLayoutModule} from '@angular/flex-layout';
import {
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDialogModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatOptionModule,
    MatProgressBarModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatTooltipModule
} from '@angular/material';
import {TranslateModule} from '@ngx-translate/core';
import {HttpClientModule} from '@angular/common/http';
import {ClipboardDirective} from '../../directives/clipboard.directive';
import {DecimalShrinkerPipe} from '../../pipes/decimal-shrinker.pipe';
import {NgCircleProgressModule} from 'ng-circle-progress';
import {RemoveBullets} from '../../pipes/remove-bullets.pipe';
import {SigninDialogComponent} from './signin/signin-dialog.component';
import {SigninCommonComponent} from './signin/signin-common.component';
import {DebugPipe} from '../../pipes/debug.pipe';
import {SafeUrlPipe} from '../../pipes/safe-url.pipe';
import { SafeHtmlPipe } from '../../pipes/safe-html';
import {NormalResultPipe} from '../../pipes/normal-result.pipe';
import {QuillModule} from 'ngx-quill';
import { GuestLayoutComponent } from './layouts/guest-layout/guest-layout.component';
import { BaseLayoutComponent } from './layouts/base-layout/base-layout.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AdminHeaderComponent } from './admin-header/admin-header.component';
import { NavigationComponent } from './navigation/navigation.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        FlexLayoutModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule,
        MatTooltipModule,
        MatOptionModule,
        MatSelectModule,
        MatMenuModule,
        MatSnackBarModule,
        MatGridListModule,
        MatButtonModule,
        MatRadioModule,
        MatChipsModule,
        MatCheckboxModule,
        MatCardModule,
        MatDialogModule,
        MatInputModule,
        HttpClientModule,
        TranslateModule,
        MatProgressBarModule,
        QuillModule,
        NgCircleProgressModule.forRoot({
            radius: 70,
            space: -10,
            outerStrokeWidth: 15,
            outerStrokeColor: '#35d8b9',
            innerStrokeColor: 'rgba(255, 255, 255, .0)',
            innerStrokeWidth: 10,
            subtitleFontSize: '50',
            subtitleColor: '#a5a5a5',
            animationDuration: 1000,
            renderOnClick: false,
            showTitle: false,
            showUnits: false,
            showBackground: false,
            responsive: true
        })
    ],
    declarations: [
        ClipboardDirective,
        DecimalShrinkerPipe,
        DebugPipe,
        SafeUrlPipe,
        SafeHtmlPipe,
        RemoveBullets,
        SigninDialogComponent,
        SigninCommonComponent,
        NormalResultPipe,
        GuestLayoutComponent,
        BaseLayoutComponent,
        AdminLayoutComponent,
        AdminHeaderComponent,
        NavigationComponent,
        MainLayoutComponent
    ],
    entryComponents: [
        SigninDialogComponent,
        SigninCommonComponent
    ],
    exports: [
        ClipboardDirective,
        DecimalShrinkerPipe,
        DebugPipe,
        SafeUrlPipe,
        SafeHtmlPipe,
        RemoveBullets,
        SigninDialogComponent,
        SigninCommonComponent,
        NormalResultPipe,
        GuestLayoutComponent,
        BaseLayoutComponent,
        AdminHeaderComponent,
        NavigationComponent
    ]
})
export class AppCommonModule {
}
