import { MatPaginatorIntl } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

export class PaginatorIntlService extends MatPaginatorIntl {
    translate: TranslateService;
    nextPageLabel     = 'Next page';
    previousPageLabel = 'Previous page';

    constructor() {
        super();
    }

    injectTranslateService(translate: TranslateService) {
        this.translate = translate;
        this.translate.onLangChange.subscribe(() => {
            this.translateLabels();
        });

        this.translateLabels();
    }

    translateLabels() {
        this.nextPageLabel = this.translate.instant('NEXT_PAGE');
        this.previousPageLabel = this.translate.instant('PREVIOUS_PAGE');
    }

}
