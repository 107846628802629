import {Component, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {isNullOrUndefined} from 'util';
import {Subscription} from 'rxjs/Subscription';
import { environment } from 'src/environments/environment';
import { StorageService } from 'src/app/services/storage.service';
import { AuthService } from 'src/app/services/auth.service';
import { AppUtl } from 'src/app/helpers/app-utl';

@Component({
    selector: 'app-base-layout',
    template: ''
})
export class BaseLayoutComponent implements OnInit, OnDestroy {
    env: any = environment; // used in the children
    maintenanceStatus: boolean = false;
    maintenanceMessage: string = '';
    currentYear: number;
    isAuthenticated = true;

    private routeSubscription: Subscription;

    constructor(protected router: Router,
                public route: ActivatedRoute,
                public translate: TranslateService,
                protected storage: StorageService,
                protected authService: AuthService) {

        this.route.queryParams.subscribe(params => {
            if (params['ref']) {
                this.storage.setStorage(localStorage);
                localStorage.setItem('refToken', params['ref']);
            }
        });

        this.currentYear = AppUtl.getCurrentYear();
    }

    static redirectToMaintenancePage(router) {
        return (!router.includes('maintenance') && !router.includes('signin') && !router.includes('contact-us'));
    }

    ngOnInit() {
        this.isAuthenticated = this.storage.getItem('isLoggedin') === 'true';
    }

    ngOnDestroy() {
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
        }
    }

}
