<div class="main-dark-version" id="main">
    <!--  header-section start  -->
    <header class="header-section" [ngClass]="{'animated fadeInDown menu-fixed header-close': fixHeader}">
        <div class="header-top">
            <div class="container">
                <div class="row justify-content-between">
                    <div class="col-lg-6 col-md-6">
                        <div class="header-top-left d-flex">
                            <div class="email-part">
                                <a href="mailto:info@cryptatory.com"><i class="fa fa-envelope"></i>info@cryptatory.com</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4">
                        <div class="header-top-right">
                            <div class="header-cart-count">
                                <a [routerLink]="['/sessions/signin', 'main']" *ngIf="!isAuth">Login</a>
                                <a href="#" (click)="logOut()" *ngIf="isAuth">Log out</a>
                                <span *ngIf="isAuth && wallet.unlockedBalance >= 0">{{username}} ( {{wallet.unlockedBalance.toFixed(2)}} )</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>



        <div class="header-bottom">
            <div class="container">
                <nav class="navbar navbar-expand-xl">
                    <a class="site-logo site-title" [routerLink]="['/']"><img src="/assets/themes/images/logo.png" alt="site-logo"></a>
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="menu-toggle"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav main-menu ml-auto">
                           <!-- <li><a [routerLink]="['/']">Main</a></li>-->
                            <li>
                                <a href="#">Main</a>
                            </li>
                            <!--  <li><a [routerLink]="['/product']">Product</a></li>&lt;!&ndash;changing rooter link&ndash;&gt;-->
                            <li>
                                <a href="#product">Product</a>
                            </li>
                           <!-- <li>
                                <a href="#contact">Contact</a>
                            </li>-->
                             <li><a [routerLink]="['/contact']">Contact us</a></li>
                        </ul>
                        <div class="header-join-part">
                            <button *ngIf="!isAuth" [routerLink]="['/sessions/signin', 'demo']" type="button" class="cmn-btn" data-toggle="modal" data-target="#exampleModal">Demo</button>
                            <button *ngIf="isAuth" [routerLink]="['/game/10']" type="button" class="cmn-btn" data-toggle="modal" data-target="#exampleModal">Demo</button>
                        </div>
                    </div>
                </nav>
            </div>
        </div><!-- header-bottom end -->
    </header>
    <!--  header-section end-->


    <router-outlet></router-outlet>

    <!-- footer-section start -->
    <footer class="footer-section">
        <div class="footer-top">
            <div class="footer-top-second">
                <div class="container">
                    <div class="row justify-content-between">
                        <div class="col-xl-2 col-lg-3 col-md-3 col-sm-6">
                            <div class="footer-widget widget-about">
                                <h3 class="widget-title">About</h3>
                                <ul class="footer-list-menu">
                                    <li><a href="#why_choose_us">Benefits</a></li>
                                    <li><a href="#how_its_works">How it Works</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-xl-2 col-lg-3 col-md-3 col-sm-6">
                            <div class="footer-widget widget-links">
                                <h3 class="widget-title">Quick links</h3>
                                <ul class="footer-list-menu">
                                    <li><a [routerLink]="['/contact']">Contact us</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <div class="footer-widget widget-subscribe">
                                <h3 class="widget-title">email newsletters</h3>
                                <div class="subscribe-part">
                                    <!--<p>Subscribe now and receive weekly newsletter for latest draw and offer news and much more!</p>-->
                                    <form class="subscribe-form">
                                        <input type="email" [(ngModel)] = "subscribeEmail" name="subs_email" id="subs_email" placeholder="Email address">
                                        <input (click)="subscribe()" type="submit" value="subscribe">
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-bottom border-top">
            <div class="container">
                <div class="row justify-content-between align-items-center">
                    <div class="col-lg-6 col-sm-7">
                        <div class="copy-right-text">
                            <p>© 2019 <a href="#">Cryptatory</a> - All Rights Reserved.</p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-5">
                        <!--<ul class="footer-social-links d-flex justify-content-end">-->
                            <!--<li><a href="#0"><i class="fa fa-facebook"></i></a></li>-->
                            <!--<li><a href="#0"><i class="fa fa-twitter"></i></a></li>-->
                            <!--<li><a href="#0"><i class="fa fa-google-plus"></i></a></li>-->
                            <!--<li><a href="#0"><i class="fa fa-instagram"></i></a></li>-->
                        <!--</ul>-->
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <!-- footer-section end -->
</div>
