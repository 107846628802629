// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,
    host: 'https://cryptatory.com/api',
    maintenanceServiceMessage: '',
    appTitle: 'Crypto Lotto',
    authHeaderPrefix: 'lotto',
    version: '0.0.1',
    supportedLangs: [
        {
            lang: 'English',
            ISOCode: 'en'
        }
    ],
    blockchains: [
        {
            id: 'BTC',
            name: 'BLOCKCHAINS.BITCOIN'
        },
        {
            id: 'ETH',
            name: 'BLOCKCHAINS.ETHEREUM'
        }
    ],
    showRememberMeOnLogin: false,
    siteKey: '6LfBuKQUAAAAAGp20jA0nSD9oVGtHFjZ887-8Jqw'
};

