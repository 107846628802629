import {Injectable} from '@angular/core';
import {NetworkHelper} from '../helpers/network.helper';
import {Observable} from 'rxjs/Observable';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {environment} from '../../environments/environment';
import {StorageService} from './storage.service';

@Injectable()
export class GameService {
    private hostUrl: string;

    constructor(private networkHelper: NetworkHelper, private storage: StorageService) {
        this.hostUrl = environment.host;
    }

    all(page: number, itemsPerPage: number, status: string): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/game/all/${page}/${itemsPerPage}/${status}`, {});
    }

    create(cellCount: number, currency: string, bids: any, reward: number, gameEnd: number): Observable<any> {
        return this.networkHelper.post(`${this.hostUrl}/game/create`, {
            cellCount: cellCount,
            currency: currency,
            bids: bids,
            reward: reward,
            gameEnd: gameEnd
        });
    }


    edit(id: string, name: string, status: string, reward: number, gameEnd: number): Observable<any> {
        return this.networkHelper.post(`${this.hostUrl}/game/edit`, {
            id: id,
            name: name,
            status: status,
            reward: reward,
            gameEnd: gameEnd
        });
    }

    current(currency: string): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/game/current/${currency}`, {});
    }

    getByOrder(id: string): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/game/by-order/${id}`, {});
    }

    lastBlocks(currency: string): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/game/last-blocks/${currency}`, {});
    }

    getById(id: string): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/game/${id}`, {});
    }

    currentByRoom(currency: string, room: string): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/game/active/${currency}/${room}`, {});
    }
}
