import {NgModule} from '@angular/core';
import {RelativeTimePipe} from './relative-time.pipe';
import {ExcerptPipe} from './excerpt.pipe';
import {ExcerptCenterPipe} from './excerptCenter.pipe';
import {HashPipe} from './hash.pipe';

@NgModule({
    declarations: [
        RelativeTimePipe,
        ExcerptPipe,
        ExcerptCenterPipe,
        HashPipe
    ],
    exports: [
        RelativeTimePipe,
        ExcerptPipe,
        ExcerptCenterPipe,
        HashPipe
    ]
})
export class CommonPipesModule {
}
