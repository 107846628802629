import {Injectable} from '@angular/core';

@Injectable()
export class StorageService implements Storage {
    readonly length: number;
    private storage: any;

    constructor() {
        if (localStorage.getItem('rememberMe') === 'true') {
            this.setStorage(localStorage);
        } else {
            this.setStorage(sessionStorage);
        }
    }

    setStorage(storage: any) {
        this.storage = storage;
    }

    key(index: number): string | null {
        return this.storage.key();
    }

    removeItem(key: string): void {
        this.storage.removeItem(key);
    }

    clear(): void {
        this.storage.clear();
    }

    getItem(key: string): string {
        return this.storage.getItem(key);
    }

    setItem(key: string, value: string) {
        if (value) {
            this.storage.setItem(key, value);
        }
    }

    [key: string]: any;
    [index: number]: string;
}
