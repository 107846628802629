import {Injectable} from '@angular/core';

@Injectable()
export class AppUtl {
    static getStatusToShow(order, translate) {
        let status = translate.instant('ORDER.STATUSES.PENDING');
        if (order.paymentStatus === 'VERIFIED') {
            status = translate.instant('ORDER.STATUSES.IN_PAYOUT');
        } else if (order.paymentStatus === 'EXPIRED') {
            status = translate.instant('ORDER.STATUSES.PENDING');
        } else if (order.paymentStatus === 'UNVERIFIED') {
            status = translate.instant('ORDER.STATUSES.IN_TRANSFER');
        }
        return status;
    }

    static makeListFromAsterisk(givenString) {
        const finalContent = {},
            content = givenString.split('*');
        finalContent['heading'] = content[0];
        finalContent['list'] = [];
        content.splice(1).forEach((contentPart) => {
            if (contentPart.length || contentPart.length > 0) {
                finalContent['list'].push(contentPart);
            }
        });
        return finalContent;
    }

    static getCurrentYear() {
        return (new Date()).getFullYear();
    }

    private static parseVideoId (url) {
        let type;
        // - Supported YouTube URL formats:
        //   - http://www.youtube.com/watch?v=My2FRPA3Gf8
        //   - http://youtu.be/My2FRPA3Gf8
        //   - https://youtube.googleapis.com/v/My2FRPA3Gf8
        // - Supported Vimeo URL formats:
        //   - http://vimeo.com/25451551
        //   - http://player.vimeo.com/video/25451551
        // - Also supports relative URLs:
        //   - //player.vimeo.com/video/25451551
    
        url.match(/(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/);
    
        if (RegExp.$3.indexOf('youtu') > -1) {
            type = 'youtube';
        } else if (RegExp.$3.indexOf('vimeo') > -1) {
            type = 'vimeo';
        }
    
        return {
            type: type,
            id: RegExp.$6
        };
    }

    public static createVideoUrl (url) {
        // Returns an iframe of the video with the specified URL.
        const videoObj = this.parseVideoId (url);
    
        if (videoObj.type === 'youtube') {
            return `//www.youtube.com/embed/${videoObj.id}`;
        } else if (videoObj.type === 'vimeo') {
            return `//player.vimeo.com/video/${videoObj.id}`;
        }
        return null;
    }
}
