import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {environment} from '../../environments/environment';
import 'rxjs/add/operator/do';
import {LoggerService} from './logger.service';
import {StorageService} from './storage.service';
import {isNullOrUndefined} from 'util';

function FeError(message, data) {
    this.message = message;
    this.data = data;
    this.toString = () => {
        return this.message;
    };
}

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(private storage: StorageService, private logger: LoggerService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.url.indexOf('/api/') === -1) {
            return next.handle(req);
        }

        let lang = localStorage.getItem('lang');
        if (isNullOrUndefined(lang)) {
            lang = 'en';
        }
        req = req.clone({
            setHeaders: {
                /*'Content-Type': 'application/json',*/
                'Accept-Language': lang
            }
        });

        if (!req.headers.has('Authorization')) {
            const token = this.storage.getItem('token');
            if (token) {
                req = req.clone({
                    setHeaders: {
                        'Authorization': `${environment.authHeaderPrefix} <${token}>`
                    }
                });
            }
        }

        const started = Date.now();
        return next
            .handle(req)
            .do(event => {
                if (event instanceof HttpResponse) {
                    const elapsed = Date.now() - started;
                    this.logger.debug(`${req.method} ${req.urlWithParams} took ${elapsed} ms.`);

                    if (!event.body.status) {
                        if (event.body.code === 401 ||
                            event.body.code === 503) {
                            this.unauthorizedHandler();
                        }

                        if (isNullOrUndefined(event.body.data)) {
                            throw new Error(event.body.message);
                        } else {
                            throw new FeError(event.body.message, event.body.data);
                        }
                    } else {
                        event = event.clone({body: event.body.result});
                        return event;
                    }
                }
            }, (err: any) => {
                if (err instanceof HttpErrorResponse) {
                    throw new Error('Network Error');
                }
            });
    }

    private unauthorizedHandler(): void {
        this.storage.removeItem('token');
        this.storage.removeItem('userId');
        this.storage.removeItem('role');
        this.storage.removeItem('name');
        this.storage.removeItem('email');
        this.storage.removeItem('avatarDirName');
        this.storage.removeItem('avatarFileName');
        this.storage.removeItem('isLoggedin');
        setTimeout(() => {
            window.location.reload();
        }, 100);
    }
}
